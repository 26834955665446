import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["dir"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", { dir: _ctx.dir }, [
    _createVNode(_component_el_form_item, {
      label: _ctx.hideLabel ? null : _ctx.generalMethods.translate(_ctx.label),
      for: _ctx.id == null ? _ctx.prop : _ctx.id,
      prop: _ctx.prop,
      rules: _ctx.rules,
      class: _normalizeClass(`${_ctx.elFormItemClassses}`)
    }, {
      label: _withCtx(() => [
        _renderSlot(_ctx.$slots, "label")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          ref: "inputRef",
          id: _ctx.id == null ? _ctx.prop : _ctx.id,
          modelValue: _ctx.localModelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
          autocomplete: _ctx.autocomplete,
          dir: _ctx.dir,
          onInput: _cache[1] || (_cache[1] = (value) => _ctx.emitEvent('input', value)),
          type: _ctx.generalMethods.valueExist(_ctx.rows) ? 'textarea' : 'text',
          rows: _ctx.rows,
          label: _ctx.ariaLabel,
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          placeholder: _ctx.placeholder,
          size: _ctx.size,
          inputmode: _ctx.inputmode
        }, _createSlots({ _: 2 }, [
          (_ctx.showPrepend)
            ? {
                name: "prepend",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "prepend")
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["id", "modelValue", "autocomplete", "dir", "type", "rows", "label", "disabled", "readonly", "placeholder", "size", "inputmode"])
      ]),
      _: 3
    }, 8, ["label", "for", "prop", "rules", "class"])
  ], 8, _hoisted_1))
}