import { RouteRecordRaw } from "vue-router";

export const websiteRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/",
		component: () => import("@/website/layout/Layout.vue"),
		children: [
			{
				path: "",
				name: "website-landing",
				component: () => import("@/website/views/Landing.vue"),
			},
			{
				path: "terms-and-conditions",
				name: "website-terms-and-conditions",
				component: () => import("@/website/views/TermsAndConditions.vue"),
			},
			{
				path: "privacy-policy",
				name: "website-privacy-policy",
				component: () => import("@/website/views/PrivacyPolicy.vue"),
			},
			{
				path: "business/features",
				name: "website-business-features",
				component: () => import("@/website/views/business/Features.vue"),
			},
			{
				path: "features",
				name: "website-features",
				component: () => import("@/website/views/business/Features.vue"),
			},
			{
				path: "business/request-free-trial",
				name: "website-business-request-free-trial",
				component: () => import("@/website/views/request-free-trial/RequestFreeTrial.vue"),
			},
			{
				path: "request-free-trial",
				name: "website-request-free-trial",
				component: () => import("@/website/views/request-free-trial/RequestFreeTrial.vue"),
			},
			{
				path: "business/dress-rental-software",
				name: "website-business-programs-salons-management-system",
				component: () => import("@/website/views/business/SalonsManagementSystem.vue"),
			},
			{
				path: "dress-rental-software",
				name: "website-programs-salons-management-system",
				component: () => import("@/website/views/business/SalonsManagementSystem.vue"),
			},
			{
				path: "contact-us",
				name: "website-contact-us",
				component: () => import("@/website/views/contact-us/ContactUs.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/request-free-trial-success",
		name: "website-request-free-trial-success",
		component: () => import("@/website/views/request-free-trial/RequestFreeTrialSuccess.vue"),
	},
	{
		path: "/:language(en|ar|)?/contact-us-success",
		name: "website-contact-us-success",
		component: () => import("@/website/views/contact-us/ContactUsSuccess.vue"),
	},
];
